import {
  DocumentAnalysisModelEnum,
  type AppConfig,
  type Environment,
  DocumentTypeEnum,
  State,
} from 'shared-domain';

import { environment } from '../environments/environment';
import { countries } from './countries';
import { companyFields } from './company';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: 'ACME',
  redirectUrl: 'https://www.dotfile.com',
  countries,
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
  documentAnalysisConfig: {
    [`document_type:${DocumentTypeEnum.proof_of_address}`]: (store: State) => ({
      automatic_approval: false,
      automatic_rejection: false,
      parameters: {
        model: DocumentAnalysisModelEnum.proof_of_address,
        max_age_in_days: 90,
      },
    }),
    [`document_type:${DocumentTypeEnum.registration_certificate}`]: (
      store: State,
    ) => ({
      automatic_approval: false,
      automatic_rejection: false,
      parameters: {
        model: DocumentAnalysisModelEnum.registration_certificate,
        max_age_in_days: 90,
      },
    }),
    [`document_type:${DocumentTypeEnum.bank_details}`]: (store: State) => ({
      automatic_approval: false,
      automatic_rejection: false,
      parameters: {
        model: DocumentAnalysisModelEnum.iban,
        iban_matching: false,
        bic_matching: false,
      },
    }),
  },
};
