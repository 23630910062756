import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'number_of_transactions_per_month',
          type: FieldTypeEnum.select,
          options: ['less_than_1000', 'between_100_1000', 'more_than_1000'],
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'business_countries',
          type: FieldTypeEnum.country,
          isRequired: true,
          isEnabled: true,
          isMulti: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
  {
    id: 'pdf_viewer_terms',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl:
        'https://acme-master.onboarding.dotfile.com/assets/terms-and-conditions.pdf',
    },
  },
];
